import { Component, Vue, Watch } from 'vue-property-decorator'

import { api, getURL } from '@/services/api'
import { Alert } from '@/services/notification'

import { ConcursosService } from '@/services/concursosServices'

//components
import PortalAdminContent from '@/components/portalAdminContent2/PortalAdminContent2.vue'

// services
import { getAllMapTiposDoc } from '@/services/documentosService'

//warppets
import { 
  wIcon,
  Breadcrumb
} from '@/components/wrappers';

@Component({
  components: {
    wIcon,
    PortalAdminContent,
    Breadcrumb
  }
})
export default class Documentos extends Vue {

  defaultService = new ConcursosService(this.$store);

  alert = new Alert(this.$store)
  isLoading = false
  list = []
  columns = [
    { field: 'idDocumento', label: 'ID' },
    { field: 'titulo', label: 'Titulo', size: '50%' },
    { field: 'descricao', label: 'Descrição' },
    { field: 'datacadastro', label: 'Data de Publicação' }
  ]
  filtro: any = {
    status: '1'
  };
  pagination: any = {
    _page: 1,
    _limit: 10,
    _orderBy: 'idDocumento',
    _order: 'desc',
    fkdocumentotipo: this.$route.params.id
  };
  bpagination = 1
  totalList = 0
  isOpenFiltro = false
  isShowFiltro = false
  arrTiposDocs = getAllMapTiposDoc()

  get titlePrincipal(){
    return getAllMapTiposDoc().find( (item: any) => {
      return item.id == this.$route.params.id;
    })
  }

  mounted() {
    if( this.$route.query._page != undefined ){
      this.pagination._page = this.$route.query._page;
    }
    
    const query = this.mountUrlQuery();
    this.getList(query)
  }

  clickEdit(item: any){
    // console.log(item);
    this.$router.push(`/tipo-documento/${this.$route.params.id}/documento/${item.idDocumento}`)
  }

  clickDelete(item: any){
    const onConfirm = (item: any) => {
      console.log(item);
    }
    this.alert.dialogConfirm('Deseja continuar com esta operação?', onConfirm)
  }

  clickBtnLimparFiltros(){
    this.filtro = {
      status: ''
    }
    const query = this.mountUrlQuery();
    this.getList(query)
  }

  clickCloseFiltro(){
    this.isShowFiltro = false;
    this.clickBtnLimparFiltros();
  }

  mountUrlQuery(){
    let query = this.mountUrlQueryPagination();
    query = query + '&' + this.mountUrlQueryFiltro();
    return query;
  }

  mountUrlQueryPagination(){
    let str  = '';
    let i = 1;
    for(const key in this.pagination){
      str = str + key + '=' + this.pagination[key];
      if( i >= 1 && i < Object.keys(this.pagination).length){
        str = str + '&'
      }
      i = i + 1;
    }
    return str;
  }
  
  mountUrlQueryFiltro(){
    let str  = '';
    let i = 1;
    for(const key in this.filtro){
      if( this.filtro[key] != '' ){
        // if(key == 'objeto'){
        //   str = str + 'objeto_like' + '=' + this.filtro[key];
        // }
        // else if(key == 'titulo'){
        //   str = str + 'titulo_like' + '=' + this.filtro[key];
        // }
        // else{
          str = str + key + '=' + this.filtro[key];
        // }
        // adiciona caracter & na query
        if( i >= 1 && i < Object.keys(this.filtro).length){
          str = str + '&'
        }
      }
      i = i + 1;
    }
    return str;
  }

  changePagination(param: any){
    this.$router.push({query: { _page: param } })
    this.pagination._page = param;
    const query = this.mountUrlQuery();
    this.getList(query);
  }

  clickBtnProcurar(){
    const query = this.mountUrlQuery();
    // console.log(query);
    this.getList(query);
  }

  getList(query: string){
    this.isLoading = true;
    const success = (response: any) => {
      this.bpagination = response.data.page;
      this.list = this.defaultService.treatListReceive(response.data.list)
      this.totalList = response.data.totalitems
    }
    const error = (error: any) => {
      console.log(error);
    }
    const url = '/documento?'+query;
    api.get(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

}
